<template>
    <v-card :loading="loading">
        <v-card-title>
            <span class="text-h5">Select Cost</span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-row>
                    <v-autocomplete
                        flat
                        solo
                        hide-details
                        outlined
                        v-model="costId"
                        :items="costs"
                        item-text="reference"
                        item-value="id"
                        label="Cost"
                        :disabled="removeBID"
                    >
                    </v-autocomplete>
                </v-row>
                <v-row class="mt-6">
                    <div class="d-flex">
                        <span>remove base BID</span>
                        <v-simple-checkbox
                            color="primary"
                            class="mt-n1"
                            v-model="removeBID"
                        />
                    </div>
                    <v-spacer />
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-btn color="red" text @click="close">
                Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                class="mx-2 white--text"
                rounded
                depressed
                :loading="loading"
                @click="changeCost"
                :disabled="!costId && !removeBID"
            >
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    name: 'ChangeBID',
    props: {
        alternate: {
            type: Object,
            required: true,
        },
        costs: {
            type: Array,
            required: true,
        },
        quoteId: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        loading: false,
        costId: undefined,
        removeBID: false,
    }),
    methods: {
        ...mapMutations(['setErrorItems']),
        async changeCost() {
            try {
                this.loading = true
                await this.$emit('changeBID', {
                    id: this.alternate.id,
                    alternatives: this.removeBID ? [] : [this.costId],
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
